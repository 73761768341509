// extracted by mini-css-extract-plugin
export var ArtistDescription = "JohnFekner-module--ArtistDescription--y3kcu";
export var ArtistInfos = "JohnFekner-module--ArtistInfos--mCwxF";
export var ButtonWrapper = "JohnFekner-module--ButtonWrapper --h4F4j";
export var CardWrapper = "JohnFekner-module--CardWrapper--FjyKG";
export var CarrouselWrapper2 = "JohnFekner-module--CarrouselWrapper2--cM5FE";
export var Citations = "JohnFekner-module--Citations--CJvaX";
export var DescriptionWrapper = "JohnFekner-module--DescriptionWrapper--JHyj3";
export var ImageWrapper = "JohnFekner-module--ImageWrapper--j6y3N";
export var LinkWrapper = "JohnFekner-module--LinkWrapper--umXPN";
export var MobileProtrait = "JohnFekner-module--MobileProtrait--by5Lx";
export var More = "JohnFekner-module--More--lqYy0";
export var MoreButton = "JohnFekner-module--MoreButton--EmSKJ";
export var NameWrapper = "JohnFekner-module--NameWrapper--19VXB";
export var PdpWrapper = "JohnFekner-module--PdpWrapper--1NHqg";
export var PhotosWrapper = "JohnFekner-module--PhotosWrapper--NbdQ7";
export var ProfilWrapper = "JohnFekner-module--ProfilWrapper--Tixxd";
export var TitleWrapper = "JohnFekner-module--TitleWrapper--uQwWH";
export var Wrapper = "JohnFekner-module--Wrapper--sAXkl";