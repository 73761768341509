
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./JohnFekner.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import JohnFeknerPhoto1 from "../../../../res/Photos site/John Fekner/Don_Leicht_Original_Space_Invader.jpg"
import JohnFeknerPhoto2 from "../../../../res/Photos site/John Fekner/2.jpg"
import JohnFeknerPhoto3 from "../../../../res/Photos site/John Fekner/3.jpg"
import JohnFeknerPhoto4 from "../../../../res/Photos site/John Fekner/4.jpg"
import JohnFeknerPhoto5 from "../../../../res/Photos site/Photos Oeuvres Disponibles/John Fekner - Sans Titre - 1981 - BD.jpg"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from '../../../../res/fekner/portrait.jpg'
import PastPresent1 from "../../../../res/fekner/past-present-1.jpg"
import PastPresent2 from "../../../../res/fekner/past-present-2.jpg"
import PastPresent3 from "../../../../res/fekner/past-present-3.png"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "JohnFekner",
  name: "JohnFekner",
  description:
  "Born on August 6, 1950 in New York, John Fekner is an American artist. He is one of the central figures of urban art. John Fekner is a street and multimedia artist who has created hundreds of environmental, social, political and conceptual works composed of words, symbols, dates and stenciled icons painted outdoors in the United States, Sweden, Canada, England and Germany. He is a painter, photographer and poet. He became known for his stenciled texts that he plastered on city walls. \"By stenciling directly onto our environment, we legitimately identify and point out places in our neighborhood that need to be built, destroyed and rebuilt. We draw attention with words and symbols to unsafe conditions, such as buildings and pollutants that can and should be removed.\".",
  pdp: Pdp,
  alt_pdp: "Photo de profil de JohnFekner.",
  photos: [
    { src: PastPresent1, name: "JohnFekner" },
    { src: PastPresent2, name: "JohnFekner" },
    { src: PastPresent3, name: "JohnFekner" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: JohnFeknerPhoto5, name: "John Fekner", artist:"John Fekner(1950)", title:"Sans titre, 1981", desc1: "Peinture aérosol sur papier", desc2 :"Spray paint on paper", desc3: "56 x 63,5 cm", desc4:" 22 x 25 in.", desc7: "- Collection particulière\n- Acquis directement auprès de l'artiste.", desc8:"<h4>-<i>Nos Fantômes</i> - exposition inaugurale, Ghost galerie, Paris, France.\n10 Septembre - 20 Novembre, 2021.</h4>" },
  ],
};

const JohnFekner = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>JOHN FEKNER <h2 style={{paddingLeft: "16px"}}>(American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1950</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <p>- NYIT Lehman College.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <p  style={{fontWeight: "bold"}}>2005</p>
          <p>- Tawkin’s New York City Walls, Hillwood Art Museum, Long Island University, Brookville, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Traces & Melody in 1’s & 0’s DCA - Percent For Art Program, Bronx, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Arts In Transit MTA Computer generated poster installed in NYC subway system.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Remnant Memory : Frangements, Fossils, Trophies, Plaques Exit Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Foreigners in the Library - Americans at the Beach LIU / C.W Post, one act play.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Selections 1978 - 1988 Bjorn Olsson Gallery, Stockholm, Sweden.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Slaves of Labor Spectacolor Piccadilly Circus, Artangel Trust, London, UK.</p>
          <p>- Techno Plaques Willoughby Sharp Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- X Americana X Semaphore Gallery, New York, USA.</p>
          <p>- Idioblast Full-length Album 8 songs 12 record.</p>
          <p>- Cassette Gazette Audiocassette book, B-sellers, Tokyo, Japan.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Enter Screenland Anders Tornberg Gallery, Lund, Sweden. (with Don Leicht)</p>
          <p>- In From The Cold Civilian Warfare, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- 2457911/RockSteady12inchEPRecord.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Nuke Freeze Disarmament Rally, June 12, 1982, Central Park, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Stencils for the Common Man Franklin Furnace, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- No TV/Read Special project at Fashion Moda, South Bronx, NY & PS 1, LIC, New York, USA.</p>
          <p>- William Paterson College, Wayne, USA.</p>
          <p>- Galerie Ahlner, Stockholm, Sweden.</p>
          <p>- The Metro Stencils/b, Toronto, Canada.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1979</p>
          <p>- Wall Blood Series, Anders Tornberg Gallery, Lund, Sweden.</p>
          <p>- No TV/Read Galerie S:t, Petri Archive of Experimental and Marginal Art, Lund,Sweden.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1978</p>
          <p>- Paper Pulp Work Memorial Gallery, University of Rochester, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1977</p>
          <p>- Mementos of a Schoolbuilding, PS 1, LIC, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1976</p>
          <p>- Barely Visible Portraits, Lehman College Gallery, Bronx. New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1974</p>
          <p>- Barely Visible Portraits, Hundred Acres Gallery, New York, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <p  style={{fontWeight: "bold"}}>2021</p>
          <p>- New York in the 80’s, Selections from the Barry Blinderman Collection, Western exhibitions, Chicago, Illinois, USA, January 8 - February 20.</p>
          <p>- #NYisOK : Our New York Times, Trotter & Shole, NY, USA, July 30 - August 14.</p>
          <p  style={{fontWeight: "bold"}}>2020</p>
          <p>- Your House is Mine, Steinberg Museum of Art, November 18 - March 21.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- More or Less, Benaki Museum of Greek Culture, Athens, Greece, September 28 - October 26.</p>
          <p></p>
          <p  style={{fontWeight: "bold"}}>2012</p>
          <p>- He was Simply A Guy Who Painted Message In The Street, Welling Court, Astoria, New York, USA.</p>
          <p>- EVERY EXIT IS AN ENTRANCE : 30 Years of Exit Art, Exit Art, New York, USA.</p>
          <p>- The Piers : Art and Sex along the New York Waterfront, The Leslie-Lohman Museum of Gay and Lesbian Art, NY Jonathan Weinberg, Curator April 3 - May 12, 2012</p>
          <p>- I STILL HAVE A DREAM, Martin Luther Kings Jr’s Birthday January 16th, 2012, on Pal’s Lounge, Atlanta Georgia</p>
          <p  style={{fontWeight: "bold"}}>2011</p>
          <p>- This is What Democracy Looks Like, The Gallatin Galleries, New York, NY Keith Miller, Curator Oct 28 - Novembre 18, 2011</p>
          <p>- NO WAR, LA VS WAR Sept 9 - 11, 2011, Organized by John Carr</p>
          <p>- Augmented Reality Advertising, Takeover in Times Square July 24th, 2011 Public Ad Campaign & The Heavy Projects</p>
          <p>- For, Against & the Truth, Organized by Stormie Mills, Linton & Kay Contemporary, Australia, Oct 20 to Nov 2, 2011</p>
          <p>- On Every Street, Curated by Michale De Feo, Samuel Owen Gallery, Greenwich Town, CT Oct 06 to Nov 03, 2011</p>
          <p>- Trail Markers and Another 4 Years (Edit/Elec08), Street Art Festival Istanbul, Turkey, October 1, 2011, Curated by Roman Tschiedl and Pertev Emre Tataban</p>
          <p>- I Have A Dream, I Have a Nightmare, Friday the 13th May 12 - June 18, 2011</p>
          <p>- Vandalog and M.A.N.Y. present Up Close and Personal, May 12th - 15th 2011, 217 West 106th Street, Apartment 1A, New York, NY 10025, Curated by Michale Rushmore, Keith Schweitzer and Michael Glatzer</p>
          <p>- Art In The Streets, Organized by Jeffrey Deitch, MoCA Los Angeles, April 17 to August 08,</p>
          <p  style={{fontWeight: "bold"}}>2011</p>
          <p>- Pantheon : A history of art from the streets of New York City, Former Donnell Library, NYC</p>
          <p>April 2 - 17, 2011</p>
          <p>- Madrid Street Advertising Takeover, Spain, April 2011</p>
          <p  style={{fontWeight: "bold"}}>2010</p>
          <p>- Mother Earth Will Survive (Your Journey Through The Secret Life Of Plants), Welling Court Mural Project organized by Ad Hoc Art, Astoria NY</p>
          <p>- L”ART URBAIN, Oct 16 - Dec 04, 2010, Addict Galerie, Paris</p>
          <p>- BLK River Festival 2010, September 10 - Oct -6, 2010, Vienna, Austria, curated by Sydney Ogidan</p>
          <p>- Power To the People, 2010 Feature, Inc, New York, NY</p>
          <p  style={{fontWeight: "bold"}}>2009</p>
          <p>- Kinder auf der Flucht-Parcels For The Promised Land, KunstBüroBerlin, Berlin, Germany</p>
          <p>- The Labyrinth Well, Exit Art, New York, NY</p>
          <p>- This is Art, Galleri Jonas Kleerup, Stockholm, Sweden Valdemar Gerdin, Curator</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- That Was Then. . . This is Now, PS 1, LIC, NY, Alanna Heiss, Curator</p>
          <p>- The Last Book, National Library of Argentina, Buenos Aires, Argentina (Organized by Luis Camnitzer)</p>
          <p>- Unzine, The Croatian Academy Glyptotheque, Zagreb, Croatia (Organized by Rafaela Dražić)</p>
          <p>- Paper Politics, Dowd Gallery, SUNY Cortland, New York (traveling show - 2006 - 08) Josh Mcphee, Curator</p>
          <p>- In-Site, The Sculptors Guild, Governors Island, New York, Jerelyn Hanrahan, Curator</p>
          <p>- Stencil Nation, Revolution Cafe, San Francisco, CA</p>
          <p>- Video Synthesis QCC Art Gallery, The City University of New York, Queens, New York, Curated by Ryan Seslow</p>
          <p  style={{fontWeight: "bold"}}>2007</p>
          <p>- That 70s Show : New York CIty in the 70s, powerHouse Arena, Brooklyn, New York, NY</p>
          <p>- Behind the seen, Ad Hoc Art, Brooklyn, New York, Michael De Feo, Curator</p>
          <p>- The Price of Nothing EFA Project Space, Hell’s Kitchen, New York, NY, September 14 - October 27, 2007</p>
          <p  style={{fontWeight: "bold"}}>2006</p>
          <p>- The Downtown Show, Grey Art Gallery/Fales Library & Grey Art Gallery, New York</p>
          <p>- 11 Spring Street, Wooster, Collective, New York, NY</p>
          <p  style={{fontWeight: "bold"}}>2005</p>
          <p>- Vintage East Village, Hal Bromm Gallery, New York, Rick Prol, Curator</p>
          <p  style={{fontWeight: "bold"}}>2004</p>
          <p>- Alternative Rendering, American Museum of the Moving Image, Astoria, New York</p>
          <p>- I Dream Electric Sheep, Ars Electronica, Eyebeam, New York, NY</p>
          <p  style={{fontWeight: "bold"}}>2003</p>
          <p>- Open for Action : Political Book Art, organized by Richard Minsky and Sharon Gilbert September 25, 2003 - December 5, 2003, The Center for Book Arts, New York, NY</p>
          <p>- WTC Site Memorial Competition Exhibition</p>
          <p>- Yes Yes Y’all The Birth of Hip Hop, Deitch Projects, Brooklyn, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- NYC-A Self Portrait, Le Case d’Arte Di pasquale Leccese, Milan, Italy</p>
          <br />
          <p  style={{fontWeight: "bold"}}>2001</p>
          <p>- From the Ashes, C.U.A.N.D.O., New York</p>
          <p>- Extra Art : A Survey of Artist Ephemera 1960 - 1999, The California College of Arts and Crafts, San Francisco, Steven Lieber, Curator, Book published by Smart Art Press</p>
          <p>- The LP Show, Exit Art, NYC, Andy Warhol Museum, Pittsburgh, PA, Experience Music Project, Seattle, WA</p>
          <p  style={{fontWeight: "bold"}}>2000</p>
          <p>- The End : An Independent Vision of Contemporary Culture : 1982 - 2000, Exit Art, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- Urban Mythologies : The Bronx represented since the 1960’s Bronx Museum of Art, NY, Lydia Yee, Curator</p>
          <p>- The Y2K Show Life Café, NYC Stefan Eins, Curator</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Raumbezogene Kunst, Bonn Kunstverein, Germany</p>
          <p>- Today’s Printmaker : Artist and Wordsmith, Hillwood Art Museum, Long Island University, Brookville, New York (curated by Anne Abeles)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Demolitions & Other Works, Philadelphia Museum of Art, Philadelphia, Pennsylvania</p>
          <p>- Mixing Messages-Graphic Design in Contemporary Culture, Cooper-Hewitt Museum & the American Institute of Graphic Arts, New York, NY</p>
          <p>- A Partial view of Fashion Moda, Lehman College Art Gallery, Bronx, New York</p>
          <p>- Cultural Economies-Histoires from the Alternative Art Movement, NYC, The Drawing Center, NYC Organized by Julie Auit</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Monumental Propaganda, Smithsonian International Gallery (Komar & Melamid, travels US & 95 - 96)</p>
          <p>- The Message is the Medium, Castle Gallery, College of New Rochelle, NY</p>
          <p>- Garbage, Thread Waxing Space, NYC</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- From Paper to Pixels, Center for Creative Imaging, Camden, Maine</p>
          <p>- Labor & Leisure, Kohler Arts Center, Sheboygan, Wisconsin</p>
          <p>- The Lure of the Local, University of Colorado at Boulder, Lucy Lippard, Curator</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- In Transit, The New Museum of Contemporary Art, New York, NY</p>
          <p>- All Words Suck, Anders Tornberg Gallery, Lund, Sweden</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- Dispossessed Installations, Florida State University Museum, FL</p>
          <p>- John Fekner / Rick Mills Prints and Painting 1986 - 92, Hillwood Art Museum, LIU</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Computer Generations : Animation & Mixed Media, Warwick Museum, RI</p>
          <p>- Earth Matters, Kingsborough Community College, Brooklyn, New York, NY</p>
          <p>- Word as Image : American Art : 1960 - 1990, Milwaukee Art Museum, Wisconsin, The Houston Contemporary Arts Center, TX, Oklahoma City Art Museum, Oklahoma. (catalogue by Russell Bowman)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- The Technological Muse, Katonah Museum, NY</p>
          <p>- Your House is Mine, Bullet Space, New York</p>
          <p>- Artist as Apolitical Sensor, Hillwood Art Museum, LIC/C.W.Post, (curator)</p>
          <p>- Anders Tornberg Gallery, Lund, Sweden</p>
          <p>- Illegal America, Exit Art, NYC Jeanette Ingberman, Papo Colo, Curators</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Artistic Tendencies in Computer Animation, Koln Art Society, Germany</p>
          <p>- Against the Wall, Helmond Museum, the Netherlands & Wiesbaden Museum, Germany</p>
          <p>- Word and Image, Lehman College, Bronx, NY, Nina Castelli Sundell, Curator</p>
          <p>- Against the Wall, An der Wand, Kunstverein Wiesbaden, Kunstverein Heidelberg, Gemeentemuseum Helmond Johannes Stahl, Curato</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Wings of Desire, street installation included in Wim Wenders film shot in Berlin, Germany</p>
          <p>- Committed to Print, Museum of Modern Art, NYC (catalogue) Deborah Wye, Curator</p>
          <p>- Intermedia Concerto, Le Lieu Festival, Quebec City, Canada (performance)</p>
          <p>- Prix Ars Electronica ,Linz, Austria</p>
          <p>- The Debt, Exit Art, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Video Cultural International, Toronto Video Festival, Canada (Award in Videotext)</p>
          <p>- NY-Berlin Exchange, Storefront for Art & Architecture, NYC (Catalogue)</p>
          <p>- Meisterwerke der Computerkunst, Linz, Austria (catalog)</p>
          <p>- Glasnost, Maria Bonk Gallery, Koln, Germany</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- State of the Art Animation, Nightflight, USA Cable Network</p>
          <p>- Drawing in Situ, Hillwood Art Museum, LIU/C.W. Post Campus (Catalogue)</p>
          <p>- Live on the Bounding Main, Franklin Furnace, Staten Island Ferry, New York (Performance)</p>
          <p>- Stadtsichten, NGBK, Berlin, Germany</p>
          <p>- 4 Americans, Stockholm Mobile, Sweden</p>
          <p>- Consensus : Today’s Art in an Overpopulated City, Exit Art, New York, NY</p>
          <p>- Artist with Public Voice, 911 Contemporary Arts Center, Seattle, Glenn Weiss, Curator</p>
          <p>- Television’s Impact on Contemporary Art, Queens Museum, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- We are the Target, Messages To The Public, Spectacolor, Times Square, New York, NY</p>
          <p>- Brave New World, Unstillingsbygninngene, Copenhagen, Denmark</p>
          <p>- Homeless at Home, Storefront for Art & Architecture, NYC (85-86) Glenn Wiess, R.Cellini and K. Park, Curators, Traveled to National Headquarter of AIA, Washington, DC</p>
          <p>- Words in Action, Milwaukee Art Museum, Wisconsin</p>
          <p>- Future Historie : The Impact of Changing Technology Anderson Gallery, Richmond, VA (catalog)</p>
          <p>- Micro Max, Now Gallery, New York, NY</p>
          <p>- Fashion Moda Benefit, Ronald Feldman Fine Arts, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- On the Wall/ On the Air, Massachusetts Institute of Technology, MA</p>
          <p>- Via Satellite, American Haus, Koln, Germany</p>
          <p>- New Sculpture, Anna Friebe Gallery, Koln, Germany</p>
          <p>- Climbing, Hal Bromm Gallery, New York</p>
          <p>- Sign on a Truck, Jenny Holzer Street Project, New York, NY</p>
          <p>- Festival D’Intervention, Performance & LP Record, Quebec, Canada</p>
          <p>- Limbo PS.1, Long Island City, New York, Carlo McCormick, Walter Robinson</p>
          <p>- Of the Streets, Fashion Moda at the Aspen Art Museum, Colorado</p>
          <p>- City Squad, Sound Installation at 8 BC, New York, NY</p>
          <p>- 25,000 Sculptors from Across the USA, Civilian Warfare, New York, NY</p>
          <p>- East Village Scene, Institute of Contemporary Art, Philadelphia, PA Janet Cardon, Curator</p>
          <p>- Artist Call, Hal Bromm Gallery, New York, NY</p>
          <p>- For and Against, Thorpe Intermedia Gallery, Dominican Convent, Sparkill, New York</p>
          <p>- Neo York, University of California at Santa Barbara, CA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Meta-Manhattan, Whitney Museum Downtown, New York, NY</p>
          <p>- David Wojnarowicz, Hal Bromm, New York (painting collaboration)</p>
          <p>- The Terminal Show, Harborside Industrial Center, Brooklyn, New York</p>
          <p>- Language, Drama, Source & Vision, The New mUseum of Contemporary Art, New York, NY</p>
          <p>- 1984 - A Preview, Ronald Feldman Fine Arts, New York, NY</p>
          <p>- Audio Art-Night Exercises, Moderna Museet, Stockholm, Sweden</p>
          <p>- Urban Pulses, Pittsburgh Center for the Arts, PA</p>
          <p>- Ansatzpunkte Kritischer Kunst heute, Bonner Kunstverein and Neue Gesellschaft für Bildende Kunst, Berlin</p>
          <p>- Art & Social Change, U.S.A Allen Memorial Art Museum, Oberlin, OH, William Olander, Curator (catalogue)</p>
          <p>- Art & Nature, Centro Internazionale Multimedia, Salerno, Italy (catalogue)</p>
          <p>- Wardline Pier Project, organized by David Wojnarowicz & Mike Bidlo, New York, NY</p>
          <p>- When Words Become Works, Minneapolis College of Art & Design, MN</p>
          <p>- Urban Activist Show, Sparc, Venice, CA</p>
          <p>- Carchwords, Caidoz, New York, NY</p>
          <p>- South Bronx Art, Fashion Moda, Bronx, NY The Black and White Show, Kenkeleba Gallery, NYC, Lorraine O’Grady, Curator</p>
          <p>- A.More Store, Jack Tilton Gallery, NYC, Group Show Hallwalls, Buffalo, NY</p>
          <p>- Art et Écologie, Le Liu, Quebec, Canada</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- The Atomic Salon, Ronald Feldman Fine Arts, New York, NY</p>
          <p>- From the Monkey to the Monitor, Fashion Moda, Bronx, New York, NY</p>
          <p>- Illegal America, Franklin Furnace, NYC Jeanette Ingberman, Papo Colo, Curators</p>
          <p>- Enthusiasm, Group Material, NYC</p>
          <p>- New Work/New York, The New Museum of Contemporary Art, NYC (catalog and traveled.)</p>
          <p>- Documenta 7, Fashion Moda Store, Kassel, Germany</p>
          <p>- Natural History, Grace Borgenicht Gallery, NY Scott Cook, Curator (catalog)</p>
          <p>- A Decision of Arms, Just Above Midtown</p>
          <p>- Octopus, El Museo Del Barrio, NY Papo Colo, Curator</p>
          <p>- Joint Forces : Artist, Community and Museum, Brooklyn Museum, Brooklyn, New York, NY</p>
          <p>- Urban Activist Art, Sparc, Venice, California</p>
          <p>- Central Park Disarmament Rally, Central Park, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Pictures Lie, The Kitchen, NYC Ingrid Sischy, Curator</p>
          <p>- Streetworks, Washington Project for the Arts, Washington D.C</p>
          <p>- Atlanta : An Emergency Exhibition, Group Material, New York, NY</p>
          <p>- 4 Locations, Papier Mache Video Institute, New Haven, CT</p>
          <p>- Fashion Moda Show, Contemporary Arts Center, New Orleans, LA</p>
          <p>- The M5 Show, Group Material (art exhibited on New York City Buses), Coca Crystal Show, MCTV, New York, NY</p>
          <p>- 10TH Anniversary Exhibition, Galerie S:T. Petri Archive of Experimental and Marginal Art, Lund, Sweden</p>
          <p>- Animals in the Arsenal, Central Park Zoo Birdhouse, Organized by City Wildlife Projects</p>
          <p>- Coney Island Show, Coney Island, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1980</p>
          <p>- Graffiti Art Success For America, Fashion Moda, South Bronx, NY, curated by John Crash Matos</p>
          <p>- Charlotte Street Stencils, 5 locations in South Bronx, New York, NY</p>
          <p>- Demonstrate, Gallery 345 Lafayette Gallery, New York, NY</p>
          <p>- Animals Living in Cities, ABC No Rio, New York, NY</p>
          <p>- Mudd Video, Mudd Club, New York, NY</p>
          <p>- Paper Work, SUNY Stonybrook, New York, NY</p>
          <p>- Public Policy Show, 625 Broadway, New York, NY</p>
          <p>- 65x12, The Drawing Center, New York, NY</p>
          <p>- 2 Nights from 4 Locations, Papier Mache Video Institute, New Haven, CT</p>
          <p>- Events : Fashion Moda, Taller Boricua, Artists, Invite at the New Museum, Catalog essay by Lynn Gumpert</p>
          <br />
          <p style={{fontWeight: "bold"}}>1979</p>
          <p>- Temporary Sites, Washington Project for the Arts, Washington D.C</p>
          <p>- Time Fuse Attitudes, Balderup Institute of Art, Arild, Sweden, Curated by Lars Vilks & Leif Eriksson</p>
          <p>- Drawing, Hal Bromm Gallery, New York, NY</p>
          <p>- Forgione Annual, Old Westbury, New York, NY</p>
          <p>- 14 Painters, Lehman College Art Gallery, Lehman College, Bronx, NY</p>
          <p>- Mini Utstilling, Galleri Wallner, Malmo, Sweden</p>
          <p>- Sydney and Frances Lewis Collection, Virginia Museum of Fine Arts, Richmond, VA</p>
          <br />
          <p style={{fontWeight: "bold"}}>1978</p>
          <p>- The Detective Show, Gorman Park, Sponsored by the Institute for Art & Urban Resources/PS1 Jackson Heights, New York (curator & participant)</p>
          <p>- The Pool, C.W.Post, Brookville, NY, curated by Russell Maltz</p>
          <p>- Drawings, 55 Mercer, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1977</p>
          <p>- Works & Projects of the 70s, PS1, LIC, New York, NY</p>
          <p>- A Month of Sundays, PS 1, LIC, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1976</p>
          <p>- Artists 76, Queens Museum, Queens, NY</p>
          <p>- Invitational, 55 Mercer, New York, NY</p>
          <br />
          <p style={{fontWeight: "bold"}}>1975</p>
          <p>- Selection, American Federation of Arts 75 - 77, (travailing museum exhibition in the US)</p>
          <p>- A Change of View, Aldrich Museum of Contemporary Art, Ridgefield, CT</p>
          <br />
          <p style={{fontWeight: "bold"}}>1974</p>
          <p>- Contemporary Reflections 73-74, Aldrich Museum of Contemporary Art, Ridgefield, CT</p>
          <br />
          <p style={{fontWeight: "bold"}}>1973</p>
          <p id="end">- Queens Talent 73, Queens Museum of Art, Queens, NY</p>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default JohnFekner;